import backgroundImage from "../images/pattern.png";

const theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: "Open Sans, sans-serif",
    heading: "inherit",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: "#2c3e50",
    background: "#f1c40f",
    primary: "##2ecc71",
    secondary: "#3498db",
    muted: "#f5f5f5",
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      backgroundImage: `url(${backgroundImage})`,
      padding: 5,
    },
    h1: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 5,
      margin: 0,
    },
    p: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      margin: 0,
    },
    a: {
      color: "primary",
    },
    img: {
      maxWidth: "100%",
      display: "block",
    },
  },
};

export default theme;
